import * as React from 'react';

import {C, H} from '../../imports';

export interface iProps {
  begin: () => void;
}

export default (props: iProps) => {
  const history = H.useHistory();
  const { t, i18n } = H.useTranslation();

  const handle_ChangeLang = (e: any) => {
    i18n.changeLanguage(e.target.value);
  }
  
  return <>
  <C.PageWrapper title="">
    <C.Grid container spacing={3} direction="column" justify="center" alignItems="center">
      <C.Grid alignItems="center" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <div>{t('Landing.Txt.Welcome')}</div>
      </C.Grid>

      <C.Grid alignItems="center" alignContent='center' item xs={9} sm={9} md={9} lg={9} xl={9}>
        <p style={{ textAlign: 'center', marginTop: 0 }}>
          {t('Landing.Txt.LineTwo')}
        </p>
        <p style={{ textAlign: 'center' }}>
          {t('Landing.Txt.LineThree')}
        </p>
      </C.Grid>

      <C.Grid alignItems="center" item xs={12} sm={12} md={12} lg={12} xl={12}>
        <C.Button variant="contained" color="primary" style={{ width: '100%'}} onClick={props.begin}>
          {t('Landing.Btn.Next')}
        </C.Button>
      </C.Grid>

      <C.Grid alignItems="center" style={{width: '50%'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
        {/* <C.Button variant="contained" color="primary" style={{ width: '100%'}} onClick={props.begin}>
          {t('Landing.Btn.Next')}
        </C.Button> */}
        <C.FormControl style={{width: '100%'}}>
          <C.InputLabel id="demo-simple-select-label">Language</C.InputLabel>
          <C.Select
            labelId="LangSelectorLabel"
            id="LangSelector"
            value={i18n.language}
            onChange={handle_ChangeLang}
          >
            <C.MenuItem value={'en'}>English</C.MenuItem>
            <C.MenuItem value={'es'}>Español</C.MenuItem>
          </C.Select>
      </C.FormControl>
      </C.Grid>
    </C.Grid>
  </C.PageWrapper>
  </>
}