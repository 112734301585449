import {
  createMuiTheme,
  makeStyles,
  createStyles,
  Theme as AugmentedTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import React from 'react';

interface iPaletteColor {
  light?: string;
  main: string;
  dark?: string;
  contrastText?: string;
}

export interface iProps {
  children: JSX.Element | JSX.Element[];
}

export default (props: iProps) => {

  const theme = createMuiTheme({
    palette : {
      primary : {
        main : '#075080',
        contrastText: 'white'
      },
      secondary : {
        main : '#EE3124',
        contrastText: 'white'
      },
      error : {
        main : '#EE3124',
        contrastText: 'white'
      },
      warning : {
        main : '#FFD100',
        contrastText: 'white'
      },
      info : {
        main : '#1CA9E1',
        contrastText: 'white'
      },
      success : {
        main : '#81C342',
        contrastText: 'white'
      }
    },
    typography: {
      fontFamily: 'Montserrat, Arial'
    }
  })

  return <ThemeProvider theme={theme}>
    {props.children}
  </ThemeProvider>

}