import React from 'react';

import { C, H } from './../../imports';

export interface iProps {
  yes: () => void;
  no: () => void;
}

export const QuestionWrapper: React.FC<iProps> = (props) => {
  const { t, i18n } = H.useTranslation();

  return <>
  {/* <C.DialogContent>
    <C.DialogContentText>
      <div style={{ display: 'flex', flexDirection: 'row'}}>
        <div style={{flex: 2}}>
          {props.children}
        </div>

        <div style={{flex: 1, display: 'flex', justifyContent: 'space-around'}}>
          <C.Button onClick={props.yes}>
            Yes
          </C.Button>

          <C.Button onClick={props.no}>
            No
          </C.Button>
        </div>
      </div>
    </C.DialogContentText>
  </C.DialogContent>

  <div style={{width: '100%', borderBottom: '5px solid black'}} /> */}

  <C.Grid container spacing={3} justify="center" alignItems="center">

    <C.Grid item xs={12}>
      <C.DialogContent>
        <C.DialogContentText>
          {props.children}
        </C.DialogContentText>
      </C.DialogContent>
    </C.Grid>

    <C.Grid justify="center" item xs={6}>
      <C.Button variant="contained" color="primary" style={{ width: '100%' }} onClick={props.yes}>
        {t('QuestionWrapper.Btn.Yes')}
      </C.Button>
    </C.Grid>

    <C.Grid item xs={6} >
      <C.Button variant="contained" color="secondary" style={{ width: '100%' }} onClick={props.no}>
        {t('QuestionWrapper.Btn.No')}
      </C.Button>
    </C.Grid>
    
  </C.Grid>
  </>
}