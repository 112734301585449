import * as React from 'react';
import Moment from 'moment';

import {C, H} from '../../imports';

export default () => {
  const { t, i18n } = H.useTranslation();
  
  return <>
    <C.PageWrapper noPaper whiteLogo color='#EE3124' title="">
      <div style={{ color: 'white', minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
          <C.Grid item style={{textAlign: 'center'}}><h1>{t('SeeReceptionist.Txt.SeeReceptionist')}</h1></C.Grid>
          <C.Grid item style={{textAlign: 'center'}}>{t('SeeReceptionist.Txt.Instructions')}</C.Grid>
          <C.Grid>
            <h1 style={{textAlign: 'center'}}>
              {Moment().format('MM/DD/YYYY')}
            </h1>
          </C.Grid>
      </div>
    </C.PageWrapper>
    {/* <C.PageWrapper noPaper whiteLogo color='#EE3124' title="">
      <span style={{color: 'white'}}>
        <C.Grid direction="column" spacing={3} alignItems="center" container>
          <C.Grid item style={{textAlign: 'center'}}><h1>Please See Reception Desk</h1></C.Grid>
          <C.Grid item style={{textAlign: 'center'}}>Please visit the reception desk for more information on how to proceed at this time.</C.Grid>
          <h1 style={{position: 'absolute', bottom: '1em'}}>
            {Moment().format('MM/DD/YYYY')}
          </h1>
        </C.Grid>
      </span>
    </C.PageWrapper> */}
  </>
}