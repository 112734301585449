import Components from './Components';
import Hooks from './Hooks';
import Screens from './Screens';

export const C = Components;
export const H = Hooks;
export const S = Screens;

export default {
  C,
  H,
  S
}