import * as React from 'react';
import Moment from 'moment';

import {C, H} from '../../imports';

export default () => {
  const { t, i18n } = H.useTranslation();
  
  return <>
    <C.PageWrapper noPaper whiteLogo color='#81C342' title="">
      <div style={{ color: 'white', minHeight: '50vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
        <C.Grid item style={{textAlign: 'center'}}><h1>{t('ThankYou.Txt.Proceed')}</h1></C.Grid>
          <C.Grid item>
            <h3 style={{textAlign: 'center'}}>{t('ThankYou.Txt.BeSafe')}</h3>
            <ul>
              <li>{t('ThankYou.Txt.Mask')}</li>
              <li>{t('ThankYou.Txt.SocialDistance')}</li>
              <li>{t('ThankYou.Txt.Sanitize')}</li>
            </ul>
          </C.Grid>
          <C.Grid>
            <h1 style={{textAlign: 'center'}}>
              {Moment().format('MM/DD/YYYY')}
            </h1>
          </C.Grid>
      </div>
    </C.PageWrapper>

    {/* <C.PageWrapper noPaper whiteLogo color='#81C342' title="">
      <span style={{color: 'white'}}>
        <C.Grid direction="column" spacing={3} alignItems="center" container>
          <C.Grid item style={{textAlign: 'center'}}><h1>Please Proceed</h1></C.Grid>
          <C.Grid item style={{textAlign: 'center'}}>Please be safe by:</C.Grid>
          <C.Grid item>
            <ul>
              <li>Wearing a mask</li>
              <li>Keep social distancing</li>
              <li>Sanitize frequently</li>
            </ul>
          </C.Grid>
          <h1 style={{position: 'absolute', bottom: '1em'}}>
            {Moment().format('MM/DD/YYYY')}
          </h1>
        </C.Grid>
      </span>
    </C.PageWrapper> */}
  </>
}