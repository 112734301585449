import React from 'react';

import Landing from './Landing';
import SeeReceptionist from './SeeReceptionist';
import ThankYou from './ThankYou';

const Q_Quarantine = React.lazy(() => import('./Q-Quarantine'));
const Q_Symptoms = React.lazy(()=> import('./Q-Symptoms'));

export default {
  Landing,
  Q_Quarantine,
  Q_Symptoms,
  SeeReceptionist,
  ThankYou
}