// import * as MaterialUI from '@material-ui/core';
import {
  CircularProgress,
  Container,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,


} from '@material-ui/core';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import {PageWrapper} from './PageWrapper';
import {QuestionWrapper} from './QuestionWrapper';
import MaterialTheming from './MaterialTheming';

export default {
  CircularProgress,
  Container,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  // ...MaterialUI,

  Router,
  Switch,
  Route,
  PageWrapper,
  QuestionWrapper,
  MaterialTheming
}