import React from 'react';
import './i18n'

import {C, H, S} from './imports';


export default () => {
  const history = H.useHistory();

  return <>
    <React.Suspense fallback={null}>
      <C.Container style={{ display: 'flex', justifyContent: 'center', border: 'solid 0px black'}}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
            <C.Switch>

              <C.Route exact path="/Q-Quarantine">
                <React.Suspense fallback={<C.CircularProgress />}>
                  <S.Q_Quarantine />
                </React.Suspense>
              </C.Route>

              <C.Route exact path="/Q-Symptoms">
                <React.Suspense fallback={<C.CircularProgress />}>
                  <S.Q_Symptoms />
                </React.Suspense>
              </C.Route>

              <C.Route exact path="/SeeReceptionist">
                <S.SeeReceptionist />
              </C.Route>

              <C.Route exact path="/ThankYou">
                <S.ThankYou />
              </C.Route>

              <C.Route>
                <S.Landing begin={() => history.push('/Q-Symptoms')} />
              </C.Route>

            </C.Switch>

            {/* <C.MobileStepper 
              variant="dots"
              steps={4}
              position="static"
              nextButton={<div>Next</div>}
              backButton={<div>Back</div>}
            /> */}
        </div>
      </C.Container>
    </React.Suspense>
  </>
}
